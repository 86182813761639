import BaseBean from "@/utils/BaseBean";
import HtxxdCard from "@/views/project/order/fy/zl/bgzl/htxxd/HtxxdCard.vue";
import XdxxdCard from "@/views/project/order/fy/zl/bgzl/xdxxd/XdxxdCard.vue";
import FpxxdCard from "@/views/project/order/fy/zl/bgzl/fpxxd/FpxxdCard.vue";

export interface IDzzlCardDataObj {
    utilInst:DzzlCardUtil
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    otherParams:any
}
export default class DzzlCardUtil extends BaseBean{
    public dataObj:IDzzlCardDataObj;
    public props:any;

    constructor(proxy:any,dataObj:IDzzlCardDataObj,props:any) {
        super(proxy);
        this.dataObj=dataObj
        this.props=props
    }
    public initBgzlInfo(res:any):any{
        //先清空
        this.dataObj.otherParams.bgzlInfo={
            htxxd:[],
            xdxxd:[],
            fpxxd:[]
        }
        res.bgzlBean.list.forEach((item:any)=>{
            let flag:boolean=false;
            switch (item.type) {
                case 0://合同信息单
                    if(this.dataObj.form.bgzlBean.yxht && this.dataObj.form.bgzlBean.yxht.indexOf(item.id)!=-1)flag=true;
                    this.dataObj.otherParams.bgzlInfo.htxxd.push({id:item.id,name:item.bghthm+'.pdf',flag: flag});
                    break;
                case 1://箱单信息单
                    if(this.dataObj.form.bgzlBean.yxxd && this.dataObj.form.bgzlBean.yxxd.indexOf(item.id)!=-1)flag=true;
                    let name=this.dataObj.utilInst.findName(res.bgzlBean.list,item,item.type);
                    this.dataObj.otherParams.bgzlInfo.xdxxd.push({id:item.id,name:name+'.pdf',flag: flag});
                    break;
                case 2://发票信息单
                    if(this.dataObj.form.bgzlBean.yxfp && this.dataObj.form.bgzlBean.yxfp.indexOf(item.id)!=-1)flag=true;
                    let name2=this.dataObj.utilInst.findName(res.bgzlBean.list,item,item.type);
                    this.dataObj.otherParams.bgzlInfo.fpxxd.push({id:item.id,name:name2+'.pdf',flag: flag});
                    break;
            }
        })
    }
    //打开合同信息单、箱单信息单、发票信息单的pdf
    public async openPdf(id:string,type:number,title:string):Promise<any>{
        let modelComp:any=HtxxdCard;
        if(type==1){
            modelComp=XdxxdCard;
        }else if(type==2){
            modelComp=FpxxdCard;
        }
        await this.utils.UtilPub.openDialog({id:id,proxy:this.proxy,addOrEdit:'/load',modelComp:modelComp,ownerComp:this.proxy,
            type:type,title: title,
            cardFrom:'DzzlCard',overflow:'hidden',contentHeight:'',showFooterBtn:false,fullscreen:true})
    }

    //展示发票和箱单的时候，需要用到合同名称和发票名称
    public findName(data:any,itemObj:any,type:number):string{
        let name:string='';
        if(type==1){//箱单
            let bghtId=itemObj.bghtId;
            let fpId=itemObj.fpId;
            let ht='',fp='';
            data.forEach((item:any)=>{
                if(!ht && item.id==bghtId)ht=item.bghthm;
                if(!fp && item.id==fpId)fp=item.fphm;
            })
            name=ht+'_'+fp;
        }else if(type==2){//发票
            let bghtId=itemObj.bghtId;
            let ht='';
            data.forEach((item:any)=>{
                if(!ht && item.id==bghtId)ht=item.bghthm;
            })
            name=ht+'_'+itemObj.fphm;
        }
        return name;
    }
}